import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            name: 'login',
            component: () => import('../views/user'),
            props: { isLogin: true },
            meta: { requiresAuth: false },
        },
        {
            path: "/pages/public/register",
            name: 'reg',
            component: () => import('../views/user'),
            props: { isLogin: false },
            meta: { requiresAuth: false },
        },
        {
            path: "/pages/home/home",
            name: 'home/home',
            component: () => import('../views/home'),
            props: { tabIndex: 0 },
        },
        {
            path: "/pages/home/grab",
            name:"home/grab",
            component: () => import('../views/home'),
            props: { tabIndex: 1 }
        },
        {
            path: "/pages/home/records",
            name:"home/records",
            component: () => import('../views/home'),
            props: { tabIndex: 2 }
        },
        {
            path: "/pages/home/profile",
            name:"home/profile",
            component: () => import('../views/home'),
            props: { tabIndex: 3 }
        },
        {
            path: "/pages/public/kefu",
            name:"kefu",
            component: () => import('../views/user/Kefu'),
        },
        {
            path: "/pages/profile/login-password",
            name:"change/login-password",
            component: () => import('../views/user/ChangePassword'),
            props: { type: 1 }
        },
        {
            path: "/pages/profile/withdraw-password",
            name:"change/withdraw-password",
            component: () => import('../views/user/ChangePassword'),
            props: { type: 2 }
        },
        {
            path: "/pages/wallet/finance",
            name:"wallet/finance",
            component: () => import('../views/wallet/WalletFinance'),
        },
        {
            path: "/pages/home/message",
            name:"home/message",
            component: () => import('../views/message/NotifyRecord'),
        },
        {
            path: "/pages/wallet/bind",
            name:"wallet/bind",
            component: () => import('../views/wallet/WalletBind'),
        },
        {
            path: "/pages/wallet/withdraw",
            name:"wallet/withdraw",
            component: () => import('../views/wallet/WalletWithdraw'),
        },
        {
            path: "/pages/wallet/exchange",
            name:"wallet/exchange",
            component: () => import('../views/wallet/WalletExchange'),
        },
        {
            path: "/pages/home/content",
            name:"content/category",
            component: () => import('../views/message/ContentDetail'),
        },
        {
            path: "/pages/checkin/checkin",
            name:"checkin/checkin",
            component: () => import('../views/user/CheckIn'),
        },
        {
            path: "/pages/home/vip",
            name:"home/vip",
            component: () => import('../views/user/Vip'),
        },
        {
            path: "/pages/home/message-detail",
            name:"home/message-detail",
            component: () => import('../views/message/NotifyDetail'),
        },
    ]
});

// 路由守卫
router.beforeEach((to, from, next) => {
    // 检查是否需要认证
    if (to.matched.some(record => {
        var requiresAuth  = record.meta.requiresAuth
        if(requiresAuth === undefined){
            return true
        }else{
            return requiresAuth
        }
    })) {
        // 假设我们有一个函数或者属性来检查用户是否已经登录
        if (!isAuthenticated()) {
            // 如果用户没有登录，则重定向到登录页面
            next({ path: '/' });
        } else {
            // 如果用户已登录，允许继续前往目标页面
            next();
        }
    } else {
        // 不需要认证，直接放行
        next();
    }
});

function isAuthenticated() {
    // 这里可以是实际的逻辑，例如检查 localStorage 或者 Vuex store 中是否有 token
    return !!localStorage.getItem('token');
}

export default router;