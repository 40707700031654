export default {
    hi_welcome: 'Hai! selamat datang',
    product_features: 'Ramalkan niat membeli dengan tepat dan hantar kempen CRM disasarkan yang menyampaikan untuk perniagaan anda dan menggembirakan pelanggan anda dengan skala yang anda perlukan..',
    login: 'Log Masuk',
    register: 'Daftar',
    login_now: 'Log Masuk Sekarang',
    username: 'Nama pengguna',
    password: 'Kata Laluan',
    forget_password: 'Lupa Kata Laluan?',
    register_account: 'Daftar Akaun?',
    phone_no: 'Telefon NO',
    login_password: 'Log Masuk Kata Laluan',
    login_password_confirm: 'Sahkan Kata Laluan Log Masuk',
    withdraw_password: 'Kata Laluan Pengeluaran',
    gender: 'Jantina',
    unknow: 'Tidak diketahui',
    female: 'Perempuan',
    male: 'Lelaki',
    refer_code: 'Kod Rujukan',
    type_here: 'Taip Di Sini',
    i_agree_terms: 'Saya bersetuju dengan terma & syarat',
    create_account: 'Buat Akaun Sekarang',
    alread_member: 'Sudah menjadi ahli?',
    cancel: 'Batal',
    done: 'Selesai',
    username_rule: 'Sila masukkan 1~30 aksara untuk nama pengguna.',
    password_rule: 'Sila masukkan kata laluan',
    phoneno_rule: 'Sila masukkan Nombor Telefon',
    password_confirm_rule: 'Dua kata laluan yang dimasukkan adalah berbeza.',
    withdraw_password_rule: 'Sila masukkan kata laluan pengeluaran',
    refre_code_rule: 'Sila masukkan kod rujukan',
    i_agree_rule: 'Sila baca dan bersetuju dengan perjanjian itu.',
    loading: 'Memuatkan...',
    home: 'Rumah',
    starting: 'Bermula',
    records: 'Rekod',
    profile: 'Profil',
    current: 'Semasa',
    today_profit: 'Hari Ini Untung',
    balance: 'Baki',
    total_assets: 'Jumlah Aset',
    applications: 'Aplikasi',
    starting_now: 'Starting Now',
    important_notice: 'Notis Penting',
    all: 'Semua',
    pending: 'Belum selesai',
    completed: 'Selesai',
    frozen: 'Beku',
    total_amout: 'Jumlah jumlah',
    profit: 'Keuntungan',
    start_mission: 'Mulakan Misi',
    price: 'Harga',
    commissions: 'Komisen',
    ration_no: 'No. Catuan',
    app_ratings: 'Penilaian Apl',
    review_goods_comment: 'Ulasan Barangan Ulasan',
    submit: 'Serahkan',
    comment: 'Ulasan',
    edit_profile: 'Edit Profil',
    profile_image: 'Imej Profil',
    upload_avatar_hint: 'Klik pada avatar untuk memuat naik',
    old_password: 'Kata Laluan Lama',
    new_password: 'Kata Laluan Baru',
    new_password_confirm: 'Sahkan Kata Laluan Baharu',
    confirm: 'Sahkan',
    forget_password_hint: '*Terlupa kata laluan lama, sila hubungi perkhidmatan pelanggan untuk mendapatkannya semula',
    old_password_rule: 'Sila masukkan kata laluan lama',
    new_password_rule: 'Sila masukkan kata laluan baharu',
    kefu_hint: 'Pilih perkhidmatan pelanggan untuk menghubungi kami',
    credit_score: 'Skor Kredit',
    events: 'Acara',
    check_in: 'Daftar Masuk',
    about_us: 'About US',
    cert: 'Sijil',
    faq: 'FAQ',
    vip: 'VIP',
    terms: 'Syarat',
    transaction: 'Transaksi',
    withdrawal: 'Pengeluaran',
    financial_info: 'Maklumat Kewangan',
    other: 'Lain-lain',
    contact_us: 'Hubungi Kami',
    notifications: 'Pemberitahuan',
    logout: 'Logout',
    certificate: 'Sijil',
    agreement_terms: 'Syarat Perjanjian',
    terms_conditions: 'Terma & Syarat',
    financial_bind_hint: 'Pengguna yang dihormati, untuk melindungi keselamatan dana anda, sila jangan masukkan kata laluan Cryptocurrency Wallet anda. Kakitangan kami tidak akan meminta anda memasukkan PIN Dompet Cryptocurrency anda.',
    network: 'Rangkaian',
    wallet_address: 'Alamat Dompet',
    wallet_address_rule: 'Sila masukkan alamat dompet',
    save: 'Simpan',
    finance: 'Kewangan',
    apply_now: 'Mohon Sekarang',
    history: 'Sejarah',
    total_balance: 'Jumlah Baki',
    withdraw_time_hint: 'Masa pemprosesan pengeluaran ialah 15-20 minit.',
    withdraw_method: 'Kaedah Tarik Balik',
    withdraw_method_hint: 'Pengeluaran akan dipindahkan ke Cryptocurrency Wallet',
    withdraw_amount: 'Amaun Pengeluaran',
    all: 'Semua',
    withdraw_password: 'Tarik balik Kata Laluan',
    withdraw_amount_rule: 'Sila masukkan jumlah pengeluaran',
    withdrawal_successful: 'Pengeluaran berjaya',
    days: 'Hari',
    no_checkin_today: 'Tidak Daftar Masuk Hari Ini',
    checkin_desc1: 'Dua (2) set aplikasi pengoptimuman diperlukan setiap hari. 5 daftar masuk, 15 daftar masuk, 30 daftar masuk diperlukan.',
    checkin_desc2: 'Isi daftar masuk untuk mendapatkan insentif. (S&K dikenakan) Untuk penjelasan lanjut sila hubungi sokongan dalam talian.',
    sun: 'Matahari',
    mon: 'Isn',
    tue: 'Sel',
    wed: 'Rabu',
    thur: 'Khamis',
    fri: 'Jumaat',
    sat: 'Sat',
    token_expire: 'Token telah tamat tempoh, sila log masuk semula.',
    lang_set: 'Tetapan bahasa',
    exchange: 'Pertukaran',
    exchange_history: 'Rekod pertukaran',
    total_coin: 'Jumlah syiling emas',
    today_get_coin: 'Saya telah memperoleh {todayCoin} syiling emas hari ini',
    coin_hint: 'Syiling emas boleh ditukar dengan wang dan didepositkan ke dalam baki anda',
    exchange_scale_title: 'Perkadaran sepadan',
    exchange_scale_value: '{exchangeRate} syiling emas pertukaran {exchangeFor} dolar AS',
    exchange_amount: 'Jumlah pertukaran',
    exchange_amount_rule: 'Sila masukkan amaun pertukaran',
    exchange_successful: 'Pertukaran berjaya',
    exchange_record: 'Tahniah, syiling emas {coin} telah berjaya ditukar dengan {amount} dolar AS dan telah didepositkan ke dalam baki anda',
    recharge: 'Isi semula',
}