export default {
    hi_welcome: 'สวัสดี! ยินดีต้อนรับ',
    product_features: 'คาดการณ์เจตนาในการซื้ออย่างแม่นยำและส่งแคมเปญ CRM ที่ตรงเป้าหมายซึ่งมอบผลลัพธ์ให้กับธุรกิจของคุณและสร้างความพึงพอใจให้กับลูกค้าด้วยขนาดที่คุณต้องการ..',
    login: 'เข้าสู่ระบบ',
    register: 'ลงทะเบียน',
    login_now: 'เข้าสู่ระบบทันที',
    username: 'ชื่อผู้ใช้',
    password: 'รหัสผ่าน',
    forget_password: 'ลืมรหัสผ่าน?',
    register_account: 'ลงทะเบียนบัญชี?',
    phone_no: 'หมายเลขโทรศัพท์',
    login_password: 'รหัสผ่านเข้าสู่ระบบ',
    login_password_confirm: 'ยืนยันรหัสผ่านเข้าสู่ระบบ',
    withdraw_password: 'รหัสผ่านการถอนเงิน',
    gender: 'เพศ',
    unknow: 'ไม่ทราบ',
    female: 'หญิง',
    male: 'ชาย',
    refer_code: 'รหัสอ้างอิง',
    type_here: 'พิมพ์ที่นี่',
    i_agree_terms: 'ฉันยอมรับข้อกำหนดและเงื่อนไข',
    create_account: 'สร้างบัญชีทันที',
    alread_member: 'เป็นสมาชิกอยู่แล้วใช่หรือไม่',
    cancel: 'ยกเลิก',
    done: 'เสร็จสิ้น',
    username_rule: 'กรุณากรอกชื่อผู้ใช้ 1~30 ตัวอักษร',
    password_rule: 'กรุณากรอกรหัสผ่าน',
    phoneno_rule: 'กรุณากรอกหมายเลขโทรศัพท์',
    password_confirm_rule: 'รหัสผ่านที่กรอกสองรหัสไม่ตรงกัน',
    withdraw_password_rule: 'กรุณากรอกรหัสผ่านการถอนเงิน',
    refre_code_rule: 'กรุณากรอกรหัสอ้างอิง',
    i_agree_rule: 'กรุณาอ่านและยอมรับข้อตกลง',
    loading: 'กำลังโหลด...',
    home: 'หน้าแรก',
    starting: 'กำลังเริ่มต้น',
    records: 'บันทึก',
    profile: 'โปรไฟล์',
    current: 'ปัจจุบัน',
    today_profit: 'กำไรวันนี้',
    balance: 'ยอดคงเหลือ',
    total_assets: 'สินทรัพย์ทั้งหมด',
    applications: 'แอปพลิเคชัน',
    starting_now: 'กำลังเริ่มต้น',
    important_notice: 'ประกาศสำคัญ',
    all: 'ทั้งหมด',
    pending: 'รอดำเนินการ',
    completed: 'เสร็จสมบูรณ์',
    frozen: 'แช่แข็ง',
    total_amout: 'จำนวนเงินทั้งหมด',
    profit: 'กำไร',
    start_mission: 'เริ่มภารกิจ',
    price: 'ราคา',
    commissions: 'คอมมิชชั่น',
    ration_no: 'เลขที่ปันส่วน',
    app_ratings: 'การจัดอันดับแอพ',
    review_goods_comment: 'ความคิดเห็นสินค้ารีวิว',
    submit: 'ส่ง',
    comment: 'แสดงความคิดเห็น',
    edit_profile: 'แก้ไขโปรไฟล์',
    profile_image: 'รูปโปรไฟล์',
    upload_avatar_hint: 'คลิกที่อวาตาร์เพื่ออัปโหลด',
    old_password: 'รหัสผ่านเดิม',
    new_password: 'รหัสผ่านใหม่',
    new_password_confirm: 'ยืนยันรหัสผ่านใหม่',
    confirm: 'ยืนยัน',
    forget_password_hint: '*ลืมรหัสผ่านเดิม โปรดติดต่อฝ่ายบริการลูกค้าเพื่อเรียกคืนรหัสผ่าน',
    old_password_rule: 'กรุณากรอกรหัสผ่านเดิม',
    new_password_rule: 'กรุณากรอกรหัสผ่านใหม่',
    kefu_hint: 'เลือกฝ่ายบริการลูกค้าเพื่อติดต่อเรา',
    credit_score: 'คะแนนเครดิต',
    events: 'กิจกรรม',
    check_in: 'เช็คอิน',
    about_us: 'เกี่ยวกับเรา',
    cert: 'ใบรับรอง',
    faq: 'คำถามที่พบบ่อย',
    vip: 'วีไอพี',
    terms: 'เงื่อนไข',
    transaction: 'ธุรกรรม',
    withdrawal: 'การถอนเงิน',
    financial_info: 'ข้อมูลทางการเงิน',
    other: 'อื่น',
    contact_us: 'ติดต่อเรา',
    notifications: 'การแจ้งเตือน',
    logout: 'ออกจากระบบ',
    certificate: 'ใบรับรอง',
    agreement_terms: 'เงื่อนไขข้อตกลง',
    terms_conditions: 'ข้อกำหนดและเงื่อนไข',
    financial_bind_hint: 'เรียนผู้ใช้ทุกท่าน เพื่อปกป้องความปลอดภัยของเงินของคุณ โปรดอย่าป้อนรหัสผ่าน Cryptocurrency Wallet ของคุณ เจ้าหน้าที่ของเราจะไม่ขอให้คุณป้อนรหัส PIN กระเป๋าเงิน Cryptocurrency ของคุณ',
    network: 'เครือข่าย',
    wallet_address: 'ที่อยู่กระเป๋าเงิน',
    wallet_address_rule: 'กรุณาใส่ที่อยู่กระเป๋าเงิน',
    save: 'บันทึก',
    finance: 'การเงิน',
    apply_now: 'สมัครเลยตอนนี้',
    history: 'ประวัติศาสตร์',
    total_balance: 'ยอดคงเหลือรวม',
    withdraw_time_hint: 'ระยะเวลาในการดำเนินการถอนเงินคือ 15-20 นาที',
    withdraw_method: 'วิธีการถอนเงิน',
    withdraw_method_hint: 'การถอนเงินจะถูกโอนไปยังกระเป๋าเงิน Cryptocurrency',
    withdraw_amount: 'จำนวนเงินที่ถอน',
    all: 'ทั้งหมด',
    withdraw_password: 'ถอนรหัสผ่าน',
    withdraw_amount_rule: 'กรุณาใส่จำนวนเงินที่ถอนออก',
    withdrawal_successful: 'การถอนเงินสำเร็จ',
    days: 'วัน',
    no_checkin_today: 'วันนี้ไม่ได้เช็คอิน',
    checkin_desc1: 'ต้องมีแอปเพิ่มประสิทธิภาพสอง (2) ชุดต่อวัน เช็คอิน 5 ครั้ง เช็คอิน 15 ครั้ง เช็คอิน 30 ครั้ง',
    checkin_desc2: 'เช็คอินให้ครบเพื่อรับสิทธิพิเศษ (ใช้ข้อกำหนดและเงื่อนไข) หากต้องการข้อมูลเพิ่มเติม โปรดติดต่อฝ่ายสนับสนุนออนไลน์',
    sun: 'อาทิตย์',
    mon: 'จันทร์',
    tue: 'อังคาร',
    wed: 'พุธ',
    thur: 'พฤหัสบดี',
    fri: 'ศุกร์',
    sat: 'เสาร์',
    token_expire: 'โทเค็นหมดอายุแล้ว โปรดลงชื่อเข้าใช้ใหม่อีกครั้ง',
    lang_set: 'การตั้งค่าภาษา',
    exchange: 'แลกเปลี่ยน',
    exchange_history: 'บันทึกการแลกเปลี่ยน',
    total_coin: 'เหรียญทองทั้งหมด',
    today_get_coin: 'วันนี้ฉันได้รับเหรียญทอง {todayCoin} แล้ว',
    coin_hint: 'เหรียญทองสามารถแลกเปลี่ยนเป็นเงินและฝากเข้ายอดเงินของคุณได้',
    exchange_scale_title: 'สัดส่วนที่สอดคล้องกัน',
    exchange_scale_value: '{exchangeRate} เหรียญทอง แลกเปลี่ยน {exchangeFor} ดอลลาร์สหรัฐ',
    exchange_amount: 'จำนวนการแลกเปลี่ยน',
    exchange_amount_rule: 'โปรดป้อนจำนวนเงินที่แลกเปลี่ยน',
    exchange_successful: 'การแลกเปลี่ยนสำเร็จ',
    exchange_record: 'ขอแสดงความยินดี เหรียญทอง {coin} เหรียญได้ถูกแลกเปลี่ยนเป็น {amount} ดอลลาร์สหรัฐ สำเร็จแล้ว และได้ฝากเข้ายอดคงเหลือของคุณแล้ว',
    recharge: 'ชาร์จพลัง',
}