import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";

export default createStore({
    plugins: [createPersistedState()],
    state(){
        return{
            baseUrl: "https://shop.bela.ltd",
            moneySymbol: '$',
            isShowUserCenterPopup: true,
            appLogo: "",
            copyRight: "",
            userDetail: {
                memberAccount: null,
                memberProfile: null,
                memberLevelIcon: null,
                referralCode: null,
                creditScore: null,
                todayProfit: null,
                balance: null,
                totalAsset: null,
                todayOrder: 0,
                maximunOrder: 0,
            },
        } 
    },

    mutations: {
        updateAppLogo(state, appLogo){
            state.appLogo = appLogo
        },
        updateCopyRight(state, copyRight){
            state.copyRight = copyRight
        },
        updateUserDetail(state, userDetail){
            state.userDetail = userDetail
        },
        showUserCenterPopup(state, isShow){
            state.isShowUserCenterPopup = isShow
        },
    },

    //为异步操作准备的
    actions: {
        updateAppLogo({ commit }, appLogo) {
            commit('updateAppLogo', appLogo);
        },
        updateCopyRight({ commit }, copyRight) {
            commit('updateCopyRight', copyRight);
        },
        updateUserDetail({ commit }, userDetail) {
            commit('updateUserDetail', userDetail);
        },
        showUserCenterPopup({ commit }, isShow){
            commit('showUserCenterPopup', isShow);
        },
    }

    
})